import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// Dependencies
import { I18nextProvider } from 'react-i18next';
import i18n from 'services/i18n';
import WithSuspense from 'components/WithSuspense';
import Header from 'components/Header';
import ScrollToTop from 'components/ScrollToTop';

// Components
import FooterHome from 'components/FooterHome';
import Footer from 'components/Footer';

// Resources
import './assets/scss/main.scss';
// import PrivacyPolicy from 'pages/PrivacyPolicy';

// Pages
const HomePage = WithSuspense(lazy(() => import('pages/Home')));
const ServicesPage = WithSuspense(lazy(() => import('pages/Services')));
const ContactPage = WithSuspense(lazy(() => import('pages/Contact')));
const WhoWeArePage = WithSuspense(lazy(() => import('pages/WhoWeAre')));
const ProjectsPage = WithSuspense(lazy(() => import('pages/Projects')));
const VacantPage = WithSuspense(lazy(() => import('pages/Vacants')));
const PageNotFound = WithSuspense(lazy(() => import('pages/PageNotFound')));
const PrivacyPolicyPage = WithSuspense(lazy(() => import('pages/PrivacyPolicy')));

export const App = () => {
	const { pathname } = useLocation();
	return (
		<ScrollToTop>
			<I18nextProvider i18n={i18n}>
				<Header />
				<Switch>
					<Route path="/" exact>
						<HomePage />
					</Route>
					<Route path={['/services', '/servicios']} exact>
						<ServicesPage />
					</Route>
					<Route path={['/contact-us', '/contacto']} exact>
						<ContactPage />
					</Route>
					<Route path={['/who-we-are', '/nosotros']} exact>
						<WhoWeArePage />
					</Route>
					<Route path={['/projects', '/trabajos']} exact>
						<ProjectsPage />
					</Route>
					<Route path={['/trabaja-con-nosotros', '/work-with-us']} exact>
						<VacantPage />
					</Route>
					<Route path={['/no-vacants']} exact>
						<VacantPage noVacants />
					</Route>
					<Route path={['/privacy-policy']} exact>
						<PrivacyPolicyPage/>
					</Route>
					<Route path="*">
						<PageNotFound />
					</Route>
				</Switch>
				{pathname === '/' ? (
					<FooterHome />
				) : (
					pathname !== '/trabajos' && pathname !== '/projects' && <Footer />
				)}
			</I18nextProvider>
		</ScrollToTop>
	);
};

export default App;
